export const serviceVersion = '2025-03-12';

export type ServiceParams = {
	getMediaItemStates: {
		userId: string;
	};
	trackMediaItemProgress: {
		userId: string;
		timezone: string;
		mediaItemId: string;
		mediaItemVariantId: string;
		progressBp: number;
	};
};

export type ServiceResponses = {
	getMediaItemStates: State[];
	trackMediaItemProgress: void;
};

type State = {
	mediaItemId: string;
	mediaItemVariantId: string;
	// Progress basis points, 0-10000 with 10000 being 100% completed
	progressBp: number;
	completed: boolean;
};
