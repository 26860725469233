import { registerRootComponent } from 'expo';
import { Platform } from 'react-native';

import { registerPolyfills } from './polyfills';
import App from './src/App';
import { registerAudio } from './src/audio';

registerPolyfills();

registerRootComponent(App);

if (Platform.OS === 'ios') {
	// For Android we register only once app is on foreground
	registerAudio();
}
