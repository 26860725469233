export const serviceVersion = '2022-07-08';

// TODO: remove

export type ServiceParams = {
	getEpisodeStates: {
		userId: string;
	};
	trackEpisodeProgress: {
		userId: string;
		soundId: string;
		progressBp: number;
	};
};

export type ServiceResponses = {
	getEpisodeStates: SoundState[];
	trackEpisodeProgress: void;
};

type SoundState = {
	soundId: string;
	progressBp: number;
};
