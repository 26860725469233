import Cher from '@wearemojo/cher';

import { ISO8601DateTime } from '../types';

export const serviceVersion = '2025-03-11';

export type ServiceParams = {
	createThread: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		assistantConfigId: string;
		threadMode: 'one_off' | 'tracking_date' | 'singleton';
		threadKey: string;
		threadPurpose: string;
		timezone: string;
		initialMessage: InitialMessage | null;
	};
	retrieveThread: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		threadId: string;
	};
	listThreads: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
	};
	respondToThread: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		threadId: string;
		idempotencyKey: string | null;
		message: UserInput;
		appContext: null | AppContext;
		timezone: string;
	};
	listMemories: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
	};
	upsertMemory: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		memoryId?: string;
		content: string;
		context: string;
		timezone: string;
		runId: string | null;
	};
	deleteMemory: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		memoryId: string;
	};
	uploadImage: {
		userIdentifier: {
			type: 'user' | 'session';
			value: string;
		};
		contentType: 'image/jpeg' | 'image/png';
		contentLength: number;
	};
};

export type ServiceResponses = {
	createThread: ThreadId;
	listThreads: ThreadOverview[];
	retrieveThread: Thread;
	respondToThread: RunId;
	listMemories: Memory[];
	upsertMemory: void;
	deleteMemory: void;
	uploadImage: UploadImageResponse;
};

type ThreadId = {
	threadId: string;
};

type RunId = {
	runId: string;
};

type MessageAgent = {
	chat: 'chat';
	safety: 'safety';
	safety_support: 'safety_support';
	summary: 'summary';
};

type ThreadStatus = 'busy' | 'error' | 'idle';

type MessageRole = {
	system: 'system';
	user: 'user';
	assistant: 'assistant';
	tool: 'tool';
};

type ActionCheckIn = {
	actionType: 'check_in';
};

type ActionContactSupport = {
	actionType: 'contact_support';
	prefilledMessage?: string;
};

type ActionContinueFlow = {
	actionType: 'continue_flow';
};

type ActionNavigate = {
	actionType: 'navigate';
	screen: 'Account' | 'Explore' | 'EroticStories' | 'Community' | 'Checkout';
};

type ActionHelplineReferral = {
	actionType: 'helpline_referral';
};

type ActionUploadImage = {
	actionType: 'upload_image';
};

export type EntryAction = {
	type: 'action';
	params:
		| ActionCheckIn
		| ActionContactSupport
		| ActionContinueFlow
		| ActionNavigate
		| ActionHelplineReferral
		| ActionUploadImage;
};

export type EntryActivities = {
	type: 'activities';
	params: {
		activities: string[];
	};
};

export type EntryPoll = {
	type: 'poll';
	params: {
		pollId: string;
	};
};

export type EntrySoundEpisodes = {
	type: 'sound_episodes';
	params: {
		episodes: string[];
	};
};

export type EntryCompletedActivity = {
	type: 'completed_activity';
	params: {
		activityId: string;
		trackingDate: string;
	};
};

export type EntryTextToCopy = {
	type: 'copiable_text';
	params: {
		copiableTexts: {
			text: string;
			cardBackgroundColor: 'blue' | 'green' | 'purple' | 'orange';
		}[];
	};
};

export type EntryImageRequest = {
	type: 'image';
	params: {
		id: string;
	};
};

export type EntryImageResponse = {
	type: 'image';
	params: {
		id: string;
		image_url: string;
	};
};

export type EntryMessageAssistant = {
	type: 'message';
	params: {
		text: string;
	};
};

export enum SuggestedResponseType {
	FunctionalMessage = 'functional_message',
	SuggestedReply = 'suggested_reply',
}

export type EntryMessageUser = {
	type: 'message';
	params: {
		text: string;
		suggestedResponseType: SuggestedResponseType | null;
	};
};

export type EntryOpenedApp = {
	type: 'opened_app';
	params: {
		trackingDate: string;
	};
};

export type EntryPollResponseRecorded = {
	type: 'poll_response_recorded';
	params: {
		pollId: string;
		options: string[];
	};
};

export type EntryRoutineUpdated = {
	type: 'routine_updated';
	params: {
		planId: string;
		trackingDate: string;
	};
};

export type EntrySessionStarted = {
	type: 'session_started';
	params: {
		trackingDate: string;
	};
};

type AssistantEntry =
	| EntryAction
	| EntryActivities
	| EntryMessageAssistant
	| EntryPoll
	| EntrySoundEpisodes
	| EntryTextToCopy;

type UserEntry =
	| EntryCompletedActivity
	| EntryImageRequest
	| EntryImageResponse
	| EntryMessageUser
	| EntryOpenedApp
	| EntryPollResponseRecorded
	| EntryRoutineUpdated
	| EntrySessionStarted;

type Suggestions =
	| { type: 'functional_messages' }
	| { type: 'suggested_replies'; content: string[] }
	| null;

export type MessageAssistantChat = {
	id: string;
	role: MessageRole['assistant'];
	content: {
		agent: MessageAgent['chat'];
		entries: AssistantEntry[] | null;
		suggestions: Suggestions;
		toolCalls: Record<string, unknown>[];
	};
};

export type MessageAssistantSafety = {
	id: string;
	role: MessageRole['assistant'];
	content: {
		agent: MessageAgent['safety'];
		entries: [
			{
				type: 'safety_output';
				params: {
					userMessageId: string;
					chatAgentMessageId: string;
					moderation: {
						user: Record<string, unknown> | null;
						chatAgent: Record<string, unknown> | null;
					};
					safetyAgent: {
						user: Record<string, unknown> | null;
						chatAgent: Record<string, unknown> | null;
					};
					shouldRegenerate: boolean;
				};
			},
		];
	};
};

export type MessageAssistantSafetySupport = {
	id: string;
	role: MessageRole['assistant'];
	content: {
		agent: MessageAgent['safety_support'];
		entries: (EntryMessageAssistant | EntryAction)[];
	};
};

export type MessageAssistantSummary = {
	id: string;
	role: MessageRole['assistant'];
	content: {
		agent: MessageAgent['summary'];
		entries: [
			{
				type: 'summary_output';
				params: {
					summary: string;
				};
			},
		];
	};
};

type MessageSystem = {
	id: string;
	role: MessageRole['system'];
	content: unknown;
};

type MessageTool = {
	id: string;
	role: MessageRole['tool'];
	content: {
		name: string;
		toolCallId: string;
	} & (
		| { outcome: 'success'; data: unknown | null; error: null }
		| { outcome: 'error'; data: null; error: Cher | null }
	);
};

type MessageUser = {
	id: string;
	role: MessageRole['user'];
	content: {
		entries: UserEntry[];
	};
};

export type Message =
	| MessageAssistantChat
	| MessageAssistantSafety
	| MessageAssistantSafetySupport
	| MessageAssistantSummary
	| MessageSystem
	| MessageTool
	| MessageUser;

export type Thread = {
	threadId: string;
	status: ThreadStatus;
	expectedPollingIntervalMs: number | null;
	messages: Message[];
};

export type ThreadOverview = {
	threadId: string;
	createdAt: ISO8601DateTime;
	updatedAt: ISO8601DateTime;
	threadPurpose: string;
	threadMode: 'one_off' | 'tracking_date' | 'singleton';
	threadKey: string;
	trackingDate: ISO8601DateTime;
};

type Memory = {
	memoryId: string;
	content: string;
	context: string;
};

export type AppContext = {
	isFirstDay: boolean;
	shouldCheckIn: boolean;
};

type UserInput = {
	role: MessageRole['user'];
	content: {
		entries: UserEntry[];
	};
};

export type InitialMessage = {
	appContext: AppContext | null;
	message: UserInput;
};

export type UploadImageResponse = {
	id: string;
	signedUrl: string | null;
};
